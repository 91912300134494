import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.openFilestackPicker.bind(this))
  }

  openFilestackPicker() {
    superbrain.picker({
      onUploadDone: (data) => {
        const filestackInputField = document.getElementById('message_image_url');
        filestackInputField.value = data.filesUploaded.map(e => e.url).join(', ');

        const buttonIcon = this.element.querySelector('.button-icon');
        buttonIcon.classList.add('text-primary');
      }
    }).open();
  }
}